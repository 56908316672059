const api = {
	user: "user",
	upload: "upload",
	role: "role",
	permission: "permission",
	client: "client",
	product: "product",
	productEntry: "product-entry",
	order: "order",
	debt: "debt",
	debtRepayment: "debt-repayment",
	encashment: "encashment",
	total: "total",

	API_URL: process.env.REACT_APP_API_URL,
	API_Version: "v1",
	CHAT_URL: process.env.REACT_APP_CHAT_URL,
};
const authentification = {
	login: "/login/private",
	verify: "/verify",
	tokenAddress: "private-token",
};
const brand = {
	name: "Fuorescence admin panel",
	link: "/home",
	logo: "/logo.svg",
};
const translate = {
	notCorrectLoginCredentials: {
		en: "Not correct login credentials",
		ru: "Неправильные учетные данные для входа",
		hy: "Սխալ մուտքանուն կամ գաղտնաբառ",
	},
	totalDebtRepayment: {
		en: "Total Debt Repayment",
		ru: "Общий возврат долга",
		hy: "Ընդամենը պարտքի վերադարձ",
	},
	debtList: {
		en: "Debt List",
		ru: "Список долгов",
		hy: "Պարտքերի ցանկ",
	},
	type: {
		en: "Type",
		ru: "Тип",
		hy: "Տեսակ",
	},
	totalDebtAlThatTime: {
		en: "Total Debt at that time",
		ru: "Общий долг на тот момент",
		hy: "Ընդամենը պարտք այդ պահին",
	},
	debtRepayment: {
		en: "Debt Repayment",
		ru: "Возврат долга",
		hy: "Պարտքի վերադարձ",
	},
	selectClient: {
		en: "Select Client",
		ru: "Выберите клиента",
		hy: "Ընտրեք հաճախորդը",
	},
	totalDebt: {
		en: "Total Debt",
		ru: "Общий долг",
		hy: "Ընդամենը պարտք",
	},
	details: {
		en: "Details",
		ru: "Детали",
		hy: "Մանրամասն",
	},
	selledProducts: {
		en: "Selled products",
		ru: "Проданные продукты",
		hy: "Վաճառված ապրանքներ",
	},
	difference: {
		en: "Difference",
		ru: "Разница",
		hy: "Տարբերություն",
	},
	totals: {
		en: "Totals",
		ru: "Итоги",
		hy: "Ընդամենը",
	},
	price2: {
		en: "Price",
		ru: "Цена",
		hy: "Գին",
	},
	orders: {
		en: "Orders",
		ru: "Заказы",
		hy: "Պատվերներ",
	},
	clients: {
		en: "Clients",
		ru: "Клиенты",
		hy: "Հաճախորդներ",
	},
	products: {
		en: "Products",
		ru: "Продукты",
		hy: "Ապրանքներ",
	},
	debts: {
		en: "Debts",
		ru: "Долги",
		hy: "Պարտքեր",
	},
	productEntries: {
		en: "Product Entries",
		ru: "Ввод продукта",
		hy: "Ապրանքի մուտք",
	},
	debtRepayments: {
		en: "Debt Repayments",
		ru: "Возврат долга",
		hy: "Պարտքի վերադարձ",
	},
	calcaulator: {
		en: "Calculator",
		ru: "Калькулятор",
		hy: "Հաշվարկային մեթոդ",
	},
	remains: {
		en: "Remain",
		ru: "Осталось",
		hy: "Մնացել է",
	},
	remain: {
		en: "Remain",
		ru: "Осталось",
		hy: "Մնացորդ",
	},
	selled: {
		en: "Selled",
		ru: "Продано",
		hy: "Վաճառված է",
	},
	selledInCarats: {
		en: "Selled in carats",
		ru: "Продано в каратах",
		hy: "Վաճառված է կարատ",
	},
	income: {
		en: "Income",
		ru: "Доход",
		hy: "Եկամուտ",
	},
	entry: {
		en: "Entry",
		ru: "Ввод",
		hy: "Մուտք",
	},
	productEntry: {
		en: "Product Entry",
		ru: "Ввод продукта",
		hy: "Ապրանքի մուտք",
	},
	ID: {
		en: "ID",
		ru: "ID",
		hy: "ID",
	},
	total: {
		en: "Total",
		ru: "Итого",
		hy: "Ընդամենը",
	},
	taxCode: {
		en: "Tax Code",
		ru: "Налоговый кодекс",
		hy: "Հարկ․ կոդ",
	},
	inputDiameter: {
		en: "Input Diameter",
		ru: "Ввод диаметра",
		hy: "Մուտքագրել Դիամետրը",
	},
	inputQuantity: {
		en: "Input Quantity",
		ru: "Ввод количества",
		hy: "Մուտքագրել քանակը",
	},
	diameter: {
		en: "Diameter",
		ru: "Диаметр",
		hy: "Դիամետր",
	},
	encashment: {
		en: "Encashment",
		ru: "Инкассация",
		hy: "Գանձում",
	},
	count: {
		en: "Count",
		ru: "Количество",
		hy: "Քանակ",
	},
	print: {
		en: "Print",
		ru: "Печать",
		hy: "Տպել",
	},
	amountMg: {
		en: "Amount (mg)",
		ru: "Количество (мг)",
		hy: "Քանակ (մգ)",
	},
	amount: {
		en: "Amount",
		ru: "Количество",
		hy: "Քանակ",
	},
	paid: {
		en: "Paid",
		ru: "Оплачено",
		hy: "Վճարված է",
	},
	inputSize: {
		en: "Input Size",
		ru: "Размер ввода",
		hy: "Մուտքագրել Չափը",
	},
	selectedClient: {
		en: "Selected Client",
		ru: "Выбранный клиент",
		hy: "Ընտրված հաճախորդ",
	},
	selectedProduct: {
		en: "Selected Product",
		ru: "Выбранный продукт",
		hy: "Ընտրված ապրանք",
	},
	selectProduct: {
		en: "Select Product",
		ru: "Выберите продукт",
		hy: "Ընտրեք ապրանքը",
	},
	totalPrice: {
		en: "Total Price",
		ru: "Общая стоимость",
		hy: "Ընդհանուր գումար",
	},
	inputWeight: {
		en: "Input Weight",
		ru: "Вес ввода",
		hy: "Մուտքագրել Քաշը",
	},
	addToCart: {
		en: "Add to cart",
		ru: "Добавить в корзину",
		hy: "Ավելացնել զամբյուղ",
	},
	selectCLient: {
		en: "Select Client",
		ru: "Выберите клиента",
		hy: "Ընտրեք հաճախորդը",
	},
	prevStep: {
		en: "Previous step",
		ru: "Предыдущий шаг",
		hy: "Նախորդ քայլ",
	},
	order: {
		en: "Order",
		ru: "Заказ",
		hy: "Պատվեր",
	},
	weightMg: {
		en: "Weight (ct)",
		ru: "Вес (ct)",
		hy: "Քաշ (ct)",
	},
	selectedSize: {
		en: "Selected Size",
		ru: "Выбранный размер",
		hy: "Ընտրված չափ",
	},
	product: {
		en: "Product",
		ru: "Продукт",
		hy: "Ապրանք",
	},
	size: {
		en: "Size",
		ru: "Размер",
		hy: "Չափս",
	},
	selectedWeight: {
		en: "Selected Weight",
		ru: "Выбранный вес",
		hy: "Ընտրված քաշ",
	},
	selectedQuantity: {
		en: "Selected Quantity",
		ru: "Выбранное количество",
		hy: "Ընտրված քանակ",
	},
	inputID: {
		en: "ID product",
		ru: "ID продукта",
		hy: "Ապրանքի ID",
	},
	inputCount: {
		en: "Input Count",
		ru: "Количество ввода",
		hy: "Մուտքի Քանակ",
	},
	selectSize: {
		en: "Select Size",
		ru: "Выберите размер",
		hy: "Ընտրեք չափը",
	},
	badge: {
		en: "Badge",
		ru: "Значок",
		hy: "Բաժին",
	},
	debt: {
		en: "Debt",
		ru: "Долг",
		hy: "Պարտք",
	},
	weight: {
		en: "Weight",
		ru: "Вес",
		hy: "Քաշ",
	},
	forward: {
		en: "Forward",
		ru: "Переслать",
		hy: "Վերանայել",
	},
	reply: {
		en: "Reply",
		ru: "Ответить",
		hy: "Պատասխանել",
	},
	delete: {
		en: "Delete",
		ru: "Удалить",
		hy: "Ջնջել",
	},
	continue: {
		en: "Continue",
		ru: "Продолжить",
		hy: "Շարունակել",
	},
	chatDirect: {
		en: "Chat Direct",
		ru: "Чат",
		hy: "Զրույց",
	},
	selectChatPartner: {
		en: "Select chat partner",
		ru: "Выберите партнера для чата",
		hy: "Ընտրեք զրույցի օիգտատիրոջը",
	},
	private: {
		en: "Private",
		ru: "Частный",
		hy: "Մասնակի",
	},
	group: {
		en: "Group",
		ru: "Группа",
		hy: "Խումբ",
	},
	messages: {
		en: "Messages",
		ru: "Сообщения",
		hy: "Նամակներ",
	},
	userArchive: {
		en: "User Archive",
		ru: "Архив пользователей",
		hy: "Օգտատերերի արխիվ",
	},
	noNotifications: {
		en: "No notifications",
		ru: "Нет уведомлений",
		hy: "Ծանուցումներ չկան",
	},
	created: {
		en: "Created",
		ru: "Создано",
		hy: "Ստեղծվեց",
	},
	days: {
		en: "days",
		ru: "дни",
		hy: "օր",
	},
	ports: {
		en: "Ports",
		ru: "Порты",
		hy: "Պորտ",
	},
	speed: {
		en: "Spped",
		ru: "Скорость",
		hy: "Արագություն",
	},
	dates: {
		en: "Dates",
		ru: "Даты",
		hy: "Ամսաթվեր",
	},
	age: {
		en: "Age",
		ru: "Возраст",
		hy: "Տարիք",
	},
	userUpdated: {
		en: "Updated",
		ru: "Обновлено",
		hy: "Թարմացվեց",
	},
	userDeleted: {
		en: "Deleted",
		ru: "Удалено",
		hy: "Ջնջվեց",
	},
	density: {
		en: "Density",
		ru: "Плотность",
		hy: "Թափություն",
	},
	extraSmall: {
		en: "Extra Small",
		ru: "Очень маленький",
		hy: "Շատ փոքր",
	},
	small: {
		en: "Small",
		ru: "Маленький",
		hy: "Փոքր",
	},
	ticketClosed: {
		en: "Ticket closed",
		ru: "Тикет закрыт",
		hy: "Տոմսը փակված է",
	},
	medium: {
		en: "Medium",
		ru: "Средний",
		hy: "Միջին",
	},
	large: {
		en: "Large",
		ru: "Большой",
		hy: "Մեծ",
	},
	extraLarge: {
		en: "Extra Large",
		ru: "Очень большой",
		hy: "Շատ մեծ",
	},
	writeUsIfThereAreAnyQuestions: {
		en: "Write us if there are any questions",
		ru: "Пишите нам, если есть вопросы",
		hy: "Գրեք մեզ, եթե կա հարցեր",
	},
	loginSuccess: {
		en: "Login success",
		ru: "Успешный вход",
		hy: "Մուտքը հաջող է",
	},
	loginSuccessMessage: {
		en: "You have successfully logged in",
		ru: "Вы успешно вошли в систему",
		hy: "Դուք հաջողությամբ մուտք եք գործել",
	},
	developedBy: {
		en: "Developed by",
		ru: "Разработано",
		hy: "Ստեղծված է",
	},
	loginFailed: {
		en: "Login failed",
		ru: "Ошибка входа",
		hy: "Մուտքը ձախողվեց",
	},
	loginFailedMessage: {
		en: "Please check your login credentials",
		ru: "Пожалуйста, проверьте свои учетные данные для входа",
		hy: "Խնդրում եմ ստուգեք ձեր մուտքանունը և գաղտնաբառը",
	},
	permissions: {
		en: "Permissions",
		ru: "Разрешения",
		hy: "Թույլտվություններ",
	},
	macAddress: {
		en: "MAC Address",
		ru: "MAC адрес",
		hy: "MAC հասցե",
	},
	ipAddress: {
		en: "IP Address",
		ru: "IP адрес",
		hy: "IP հասցե",
	},
	deadline: {
		en: "Deadline",
		ru: "Крайний срок",
		hy: "Վերջնաժամկետ",
	},
	printedCount: {
		en: "Printed Count",
		ru: "Количество напечатанных",
		hy: "Տպված Քանակ",
	},
	uploadDate: {
		en: "Upload Date",
		ru: "Дата загрузки",
		hy: "Բեռնման ամսաթիվ",
	},
	seconds: {
		en: "seconds",
		ru: "секунды",
		hy: "վարկյան",
	},
	rightNow: {
		en: "Right now",
		ru: "Прямо сейчас",
		hy: "Հիմա",
	},
	completed: {
		en: "Completed",
		ru: "Завершено",
		hy: "Ավարտված է",
	},
	update: {
		en: "Update",
		ru: "Обновить",
		hy: "Թարմացում",
	},
	ago: {
		en: "ago",
		ru: "назад",
		hy: "առաջ",
	},
	starts: {
		en: "Starts",
		ru: "Начинается",
		hy: "Սկսվել է",
	},
	ends: {
		en: "Ends",
		ru: "Заканчивается",
		hy: "Ավարտվել է",
	},
	hamadzaynecnox: {
		en: "Hamadzaynecnox",
		ru: "Хамадзайнэцнох",
		hy: "Համաձայնեցնող",
	},
	shagrgirPatasxanatu: {
		en: "Shagrgir Patasxanatu",
		ru: "Шагргир Патасханату",
		hy: "Համաձայնեցման համակարգող",
	},
	userVisited: {
		en: "User visited",
		ru: "Пользователь посетил",
		hy: "Օգտատերը այցելեց",
	},
	userEntered: {
		en: "User entered",
		ru: "Пользователь вошел",
		hy: "Օգտատերը մուտք գործեց",
	},
	texaznnox: {
		en: "Texaznnox",
		ru: "Техазннох",
		hy: "Տեղազննող",
	},
	hamaynqiPatasxanatu: {
		en: "TIM Hamakargox",
		ru: "ТИМ Hamakargox",
		hy: "ՏԻՄ համակարգող",
	},
	timeline: {
		en: "Timeline",
		ru: "Хронология",
		hy: "Ժամանակագրություն",
	},
	error: {
		en: "Error",
		ru: "Ошибка",
		hy: "Սխալ",
	},
	previousStep: {
		en: "Previous step",
		ru: "Предыдущий шаг",
		hy: "Նախորդ քայլ",
	},
	nextStep: {
		en: "Next step",
		ru: "Следующий шаг",
		hy: "Հաջորդ քայլ",
	},
	yesNextStep: {
		en: "Yes, go to the next step",
		ru: "Да, перейти к следующему шагу",
		hy: "Այո, անցել հաջորդ քայլին",
	},
	yesPreviousStep: {
		en: "Yes, go to the previous step",
		ru: "Да, перейти к предыдущему шагу",
		hy: "Այո, անցել նախորդ քայլին",
	},
	youWontBeAbleToRevert: {
		en: "You won't be able to revert this!",
		ru: "Вы не сможете отменить это!",
		hy: "Դուք չեք կարող կրկին վերադառնալ այսը!",
	},
	somethingWentWrong: {
		en: "Something went wrong",
		ru: "Что-то пошло не так",
		hy: "Ինչ-որ բան անհաջող է",
	},
	showAllSteps: {
		en: "Show all steps",
		ru: "Показать все шаги",
		hy: "Ցուցադրել բոլոր քայլերը",
	},
	hideAllSteps: {
		en: "Hide all steps",
		ru: "Скрыть все шаги",
		hy: "Թաքցնել բոլոր քայլերը",
	},
	hideTimeline: {
		en: "Hide timeline",
		ru: "Скрыть хронологию",
		hy: "Թաքցնել ժամանակագրությունը",
	},
	showTimeline: {
		en: "Show timeline",
		ru: "Показать хронологию",
		hy: "Ցուցադրել ժամանակագրությունը",
	},
	yourFileHasBeenNotSaved: {
		en: "Your file has not been saved.",
		ru: "Ваш файл не был сохранен.",
		hy: "Ձեր ֆայլը չի պահպանվել",
	},
	showProjectDetails: {
		en: "Show project details",
		ru: "Показать детали проекта",
		hy: "Ցուցադրել նախագծի մանրամասները",
	},
	hideProjectDetails: {
		en: "Hide project details",
		ru: "Скрыть детали проекта",
		hy: "Թաքցնել նախագծի մանրամասները",
	},
	step: {
		en: "Step",
		ru: "Шаг",
		hy: "Քայլ",
	},
	somethingWentWrongInCreatingSteps: {
		en: "Something went wrong in creating steps! Please try again",
		ru: "Что-то пошло не так при создании шагов! Пожалуйста, попробуйте еще раз",
		hy: "Ինչ-որ բան անհաջող է ստեղծել քայլերը։ Խնդրում եմ կրկին փորձեք",
	},
	errorpageTitle: {
		en: "You have found a secret place.",
		ru: "Вы нашли секретное место.",
		hy: "Դուք գտել եք գաղտնի վայրը։",
	},
	errorpageText: {
		en: "Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.",
		ru: "К сожалению, это только страница 404. Возможно, вы ошиблись в адресе или страница была перемещена на другой URL.",
		hy: "Ներողություններով, սա միայն 404 էջն է։ Դուք կարող եք սխալ գրել հասցեն, կամ էջը տեղափոխվել է այլ URL-ի վրա։",
	},
	errorpageButton: {
		en: "Take me back to home page",
		ru: "Верните меня на домашнюю страницу",
		hy: "Վերադարձեք ինձ դեպի գլխավոր էջ",
	},
	interview1: {
		en: "Interview 1",
		ru: "Интервью 1",
		hy: "Հարցազրույց",
	},
	interview2: {
		en: "Interview 2",
		ru: "Интервью 2",
		hy: "Ծանոթություն",
	},
	interview3: {
		en: "Interview 3",
		ru: "Интервью 3",
		hy: "Ուսուցում",
	},
	interview4: {
		en: "Interview 4",
		ru: "Интервью 4",
		hy: "Փորձաշրջան",
	},
	yourFileHasBeenSaved: {
		en: "Your file has been saved.",
		ru: "Ваш файл был сохранен.",
		hy: "Ձեր ֆայլը պահպանված է",
	},
	deleted: {
		en: "Deleted",
		ru: "Удален",
		hy: "Ջնջված է",
	},
	yourFileHasBeenDeleted: {
		en: "Your file has been deleted.",
		ru: "Ваш файл был удален.",
		hy: "Ձեր ֆայլը ջնջվել է",
	},
	table: {
		en: "Table",
		ru: "Таблица",
		hy: "Աղյուսակ",
	},
	toDo: {
		en: "To Do",
		ru: "Сделать",
		hy: "Կատարել",
	},
	done: {
		en: "Done",
		ru: "Готово",
		hy: "Ավարտված է",
	},
	slider: {
		en: "Slider",
		ru: "Слайдер",
		hy: "Սլայդեր",
	},
	historyTable: {
		en: "History table",
		ru: "Таблица истории",
		hy: "Պատմության աղյուսակ",
	},
	minutes: {
		en: "minutes",
		ru: "минуты",
		hy: "րոպե",
	},
	closed: {
		en: "Closed",
		ru: "Закрыто",
		hy: "Փակված է",
	},
	loading: {
		en: "Loading",
		ru: "Загрузка",
		hy: "Բեռնում",
	},
	swal: {
		error: {
			en: '"Something went wrong!"',
			ru: '"Что-то пошло не так!"',
			hy: '"Ինչ-որ բան անհաջող է!"',
		},
		success: {
			en: "Success",
			ru: "Успех",
			hy: "Հաջողություն",
		},
		warning: {
			en: "Warning",
			ru: "Предупреждение",
			hy: "Զգուշացում",
		},
		info: {
			en: "Info",
			ru: "Информация",
			hy: "Տեղեկություն",
		},
	},

	selectFiles: {
		en: "Select files",
		ru: "Выберите файлы",
		hy: "Ընտրեք ֆայլերը",
	},
	maxSize: {
		en: "Max size",
		ru: "Максимальный размер",
		hy: "Մաքսիմալ չափ",
	},
	attach: {
		en: "Attach",
		ru: "Прикрепить",
		hy: "Կցել",
	},
	image: {
		en: "Image",
		ru: "Изображение",
		hy: "Նկար",
	},
	pdf: {
		en: "PDF",
		ru: "PDF",
		hy: "PDF",
	},
	files: {
		en: "Files",
		ru: "Файлы",
		hy: "Ֆայլեր",
	},
	file: {
		en: "File",
		ru: "Файл",
		hy: "Ֆայլ",
	},
	visitation: {
		en: "Visitation",
		ru: "Визитация",
		hy: "Այցելում",
	},
	dropzoneText: {
		en: "Drag images here or click to select files",
		ru: "Перетащите изображения сюда или нажмите, чтобы выбрать файлы",
		hy: "Քաշեք նկարները այստեղ կամ սեղմեք, որ ընտրեք ֆայլերը",
	},
	inputNumber: {
		en: "Input number",
		ru: "Ввод номера",
		hy: "Համարի մուտք",
	},
	inputInLatin: {
		en: "Input in Latin",
		ru: "Ввод на кирилице",
		hy: "Մուտքագրում լատինատառերով",
	},
	inputInArmenian: {
		en: "Input in Armenian",
		ru: "Ввод на армянском",
		hy: "Մուտքագրում հայերեն",
	},
	inputInRussian: {
		en: "Input in Russian",
		ru: "Ввод на русском",
		hy: "Մուտքագրում ռուսերեն",
	},
	software: {
		en: "Software",
		ru: "Программное обеспечение",
		hy: "Համակարգային ծրագիր",
	},
	fired: {
		en: "Fired",
		ru: "Уволен",
		hy: "Պայմանագրի խզում",
	},
	working: {
		en: "Contract",
		ru: "Контракт",
		hy: "Պայմանագիր",
	},
	maternityLeave: {
		en: "Maternity",
		ru: "Декрет",
		hy: "Դեկրետ",
	},
	registeredAddress: {
		en: "Registered Address",
		ru: "Зарегистрированный адрес",
		hy: "Գրանցված հասցե",
	},
	workingStatus: {
		en: "Working Status",
		ru: "Рабочий статус",
		hy: "Աշխատակցի Կարգավիճակ",
	},
	printer: {
		en: "Printer",
		ru: "Принтер",
		hy: "Տպիչ",
	},
	ip: {
		en: "IP",
		ru: "IP",
		hy: "IP",
	},
	description: {
		en: "Description",
		ru: "Описание",
		hy: "Նկարագրություն",
	},
	closedDate: {
		en: "Closed Date",
		ru: "Дата закрытия",
		hy: "Փակված է",
	},
	selectClosedDate: {
		en: "Select closed date",
		ru: "Выберите дату закрытия",
		hy: "Ընտրեք փակված ամսաթիվ",
	},
	add: {
		en: "Add",
		ru: "Добавить",
		hy: "Ավելացնել",
	},
	languagePickerLabel: {
		en: "Language",
		ru: "Язык",
		hy: "Լեզու",
	},
	themePickerLabel: {
		en: "Theme",
		ru: "Тема",
		hy: "Թեմա",
	},
	settings: {
		en: "Settings",
		ru: "Настройки",
		hy: "Կարգավորումներ",
	},
	logOut: {
		en: "Log out",
		ru: "Выйти",
		hy: "Ելք",
	},
	leader: {
		en: "Leader",
		ru: "Руководитель",
		hy: "Ղեկավար",
	},
	linkCopied: {
		en: "Link copied",
		ru: "Ссылка скопирована",
		hy: "Լինկը պատճենվել է",
	},
	cart: {
		en: "Cart",
		ru: "Корзина",
		hy: "Աշխատակիցներ",
	},
	passportNo: {
		en: "Passport No",
		ru: "Паспорт №",
		hy: "Անձնագրի №",
	},
	idCart: {
		en: "ID Cart",
		ru: "ID Карта",
		hy: "ID քարտ",
	},
	idCardStartDate: {
		en: "ID Card Start Date",
		ru: "Дата начала ID карты",
		hy: "ID քարտի սկիզբի ամսաթիվ",
	},
	idCardEndDate: {
		en: "ID Card End Date",
		ru: "Дата окончания ID карты",
		hy: "ID քարտի ավարտի ամսաթիվ",
	},
	bankCart: {
		en: "JNJVOX",
		ru: "JNJVOX",
		hy: "JNJVOX",
	},
	bankEmitet: {
		en: "Bank",
		ru: "Банк",
		hy: "Բանկ",
	},
	bankAccount: {
		en: "Bank Account",
		ru: "Банковская карта",
		hy: "Բանկային քարտ",
	},
	contractStartDate: {
		en: "Contract Start Date",
		ru: "Дата начала договора",
		hy: "Պայմանագրի սկիզբի ամսաթիվ",
	},
	socialCart: {
		en: "Social Cart",
		ru: "Социальная карта",
		hy: "Սոցիալական քարտ",
	},
	contractEndDate: {
		en: "Contract End Date",
		ru: "Дата окончания договора",
		hy: "Պայմանագրի ավարտի ամսաթիվ",
	},
	passportEndDate: {
		en: "Passport End Date",
		ru: "Дата окончания паспорта",
		hy: "Անձնագրի ավարտի ամսաթիվ",
	},
	passportStartDate: {
		en: "Passport Start Date",
		ru: "Дата выдачи паспорта",
		hy: "Անձնագրի տրման ամսաթիվ",
	},
	changedDate: {
		en: "Change Date",
		ru: "Дата изменения",
		hy: "Փոփոխության ամսաթիվը",
	},
	email: {
		en: "Email",
		ru: "Эл. адрес",
		hy: "Էլ. հասցեն",
	},
	department: {
		en: "Department",
		ru: "Отделение",
		hy: "Բաժին",
	},
	pc: {
		en: "Computer",
		ru: "Компьютер",
		hy: "Համակարգիչ",
	},
	position: {
		en: "Position",
		ru: "Должность",
		hy: "Հաստիք",
	},
	user: {
		en: "User",
		ru: "Пользователь",
		hy: "Օգտատեր",
	},
	banner: {
		en: "Banner",
		ru: "Баннер",
		hy: "Բաններ",
	},
	ordersCart: {
		en: "Orderes chart",
		ru: "График заказов",
		hy: "Պատվերների գծապատկեր",
	},
	quantity: {
		en: "Quantity",
		ru: "Количество",
		hy: "Քանակ",
	},
	color: {
		en: "Color",
		ru: "Цвет",
		hy: "Գույն",
	},
	addToList: {
		en: "Add to list",
		ru: "Добавить в лист",
		hy: "Ավելացնել լիստում",
	},
	noContent: {
		en: "There is no content",
		ru: "Нет контента",
		hy: "Բովանդակություն չկա",
	},
	paymentMethod: {
		en: "Payment method",
		ru: "Метод оплаты",
		hy: "Վճարման եղանակ",
	},
	paymentSystem: {
		en: "Payment System",
		ru: "Платежная система",
		hy: "Վճարային համակարգ",
	},
	deliveryMethod: {
		en: "Delivery method",
		ru: "Способ доставки",
		hy: "Առաքման եղանակը",
	},
	deliveryAddress: {
		en: "Delivery Address",
		ru: "Адресс доставки",
		hy: "Առաքման հասցե",
	},
	placeholder: {
		en: "Placeholder",
		ru: "Ввод на кирилице",
		hy: "Մուտքագրում հայատառ",
	},
	username: {
		en: "Username",
		ru: "Имя пользователя",
		hy: "Օգտատիրոջ անուն",
	},
	login: {
		en: "Login",
		ru: "Логин",
		hy: "Մուտք",
	},
	loginToStartSession: {
		en: "Sign in to start a session",
		ru: "Войдите, чтобы начать сеанс",
		hy: "Մուտք գործեք՝ սկսելու համար",
	},
	loginButton: {
		en: "Log in",
		ru: "Войти",
		hy: "Մուտք",
	},
	chatID: {
		en: "Chat ID",
		ru: "ID чата",
		hy: "Չատի ID",
	},
	minute: {
		en: "minute",
		ru: "минута",
		hy: "րոպե",
	},
	reset: {
		en: "Reset",
		ru: "Збросить",
		hy: "Չեղարկել",
	},
	notifications: {
		en: "Notifications",
		ru: "Уведомления",
		hy: "Ծանուցումները",
	},
	supportText: {
		en: "For all questions write",
		ru: "По всем вопросам пишите",
		hy: "Բոլոր հարցերի համար գրեք",
	},
	incorrectLogin: {
		en: "You entered an incorrect username or password",
		ru: "Вы ввели неверный логин или пароль",
		hy: "Դուք մուտքագրել եք սխալ օգտվողի անուն կամ գաղտնաբառ",
	},
	address: {
		en: "Address",
		ru: "Адрес",
		hy: "Հասցե",
	},
	dashboard: {
		en: "Dashboard",
		ru: "Доска",
		hy: "Վահանակ",
	},
	password: {
		en: "Password",
		ru: "Пароль",
		hy: "Գաղտնաբառ",
	},
	href: {
		en: "Href",
		ru: "Ссылка",
		hy: "Հղում",
	},
	name: {
		en: "Name",
		ru: "Имя",
		hy: "Անուն",
	},
	firstName: {
		en: "First Name",
		ru: "Имя",
		hy: "Անուն",
	},
	lasts: {
		en: "Lasts",
		ru: "Длилось",
		hy: "Տևեց",
	},
	lastName: {
		en: "Last Name",
		ru: "Фамилия",
		hy: "Ազգանուն",
	},
	dateOfBirth: {
		en: "Date Of Birth",
		ru: "Дата рождения",
		hy: "Ծննդյան ամսաթիվ",
	},
	desc: {
		en: "Description",
		ru: "Оисание",
		hy: "Նկարագրություն",
	},
	subDesc: {
		en: "Sub Description",
		ru: "Под описание",
		hy: "Ենթա նկարագրություն",
	},
	cannotPayOnline: {
		en: "Cannot pay online",
		ru: "Невозможно оплатить онлайн",
		hy: "Հնարավոր չէ վճարել առցանց",
	},
	notAvailable: {
		en: "Product not available",
		ru: "Продукт недоступен",
		hy: "Ապրանքը հասանելի չէ",
	},
	role: {
		en: "Role",
		ru: "Роль",
		hy: "Դեր",
	},
	roles: [
		{
			_id: "admin",
			name: { en: "Admin", ru: "Администратор", hy: "Ադմին" },
		},
		{
			_id: "manager",
			name: { en: "Manager", ru: "Управляющий", hy: "Մենեջեր" },
		},
		{
			_id: "editor",
			name: { en: "Editor", ru: "Редактор", hy: "Խմբագիր" },
		},
	],
	rate: {
		en: "Rate",
		ru: "Показатель",
		hy: "Գործակից",
	},
	price: {
		en: "Price",
		ru: "Цена",
		hy: "Գումար",
	},
	company: {
		en: "Company",
		ru: "Компания",
		hy: "Ընկերություն",
	},
	socialNetworkLink: {
		en: "Social Network Link",
		ru: "Ссылка на социальную сеть",
		hy: "Սոցիալական ցանցի հղումը",
	},
	phone: {
		en: "Phone",
		ru: "Телефон",
		hy: "Հեռախոս",
	},
	priceBasic: {
		en: "Price Basic",
		ru: "Базовая цена",
		hy: "Հիմնական գինը",
	},
	priceB2b: {
		en: "Price Corporate",
		ru: "Цена Корпоративный",
		hy: "Գին Կորպորատիվ",
	},
	startDate: {
		en: "Start Date",
		ru: "Дата начала",
		hy: "Մեկնարկի ամսաթիվ",
	},
	endDate: {
		en: "End Date",
		ru: "Дата окончания",
		hy: "Ավարտի ամսաթիվ",
	},
	status: {
		en: "Status",
		ru: "Статус",
		hy: "Ստատուս",
	},
	paidStatus: {
		en: "Paid Status",
		ru: "Статус оплаты",
		hy: "Վճարման ստատուս",
	},
	client: {
		en: "Client",
		ru: "Клиент",
		hy: "Հաճախորդ",
	},
	genders: [
		{
			name: {
				en: "Male",
				ru: "Мужчина",
				hy: "Արական",
			},
		},
		{
			name: {
				en: "Female",
				ru: "Женщина",
				hy: "իգական",
			},
		},
	],
	gender: {
		en: "Gender",
		ru: "Пол",
		hy: "Սեռ",
	},
	familyStatuses: [
		{
			name: {
				en: "Single",
				ru: "Одинокий",
				hy: "Միայնակ",
			},
		},
		{
			name: {
				en: "Married",
				ru: "В браке",
				hy: "Ամուսնացած",
			},
		},
	],
	more: {
		en: "Additional",
		ru: "Дополнительный",
		hy: "Լրացուցիչ",
	},
	addNew: {
		en: "Add New",
		ru: "Добавить",
		hy: "Ավելացնել",
	},
	create: {
		en: "Order",
		ru: "Заказ",
		hy: "Պատվեր",
	},
	save: {
		en: "Save",
		ru: "Сохранить",
		hy: "Պահպանել",
	},
	sent: {
		en: "Sent",
		ru: "Отправленно",
		hy: "Ուղարկված է",
	},
	send: {
		en: "Send",
		ru: "Отправить",
		hy: "Ուղարկել",
	},
	open: {
		en: "Open",
		ru: "Открыть",
		hy: "Բացել",
	},
	remove: {
		en: "Remove",
		ru: "Удалить",
		hy: "Ջնջել",
	},
	cancel: {
		en: "Cancel",
		ru: "Отменить",
		hy: "Չեղարկել",
	},
	edit: {
		en: "Edit",
		ru: "Редактировать",
		hy: "Խմբագրել",
	},
	write: {
		en: "Write",
		ru: "Написать",
		hy: "Գրել",
	},
	selectSomething: {
		en: "Select something",
		ru: "Выберите что-нибудь",
		hy: "Ընտրեք մի բան",
	},
	close: {
		en: "Close",
		ru: "Закрыть",
		hy: "Փակել",
	},
	closeTicket: {
		en: "Close Ticket",
		ru: "Закрыть билет",
		hy: "Փակել տոմսը",
	},
	index: {
		en: "#",
		ru: "#",
		hy: "#",
	},
	developed: {
		en: "Developed",
		ru: "Разработанно",
		hy: "Ստեղծված է",
	},
	version: {
		en: "Version",
		ru: "Версия",
		hy: "Տարբերակ",
	},
	mainInformation: {
		en: "Basic information",
		ru: "Основная информация",
		hy: "Հիմնական տեղեկատվություն",
	},
	city: {
		en: "City",
		ru: "Город",
		hy: "Քաղաք",
	},
	voltage: {
		en: "Voltage",
		ru: "Напряжение",
		hy: "Լարման մակարդակ",
	},
	workingMinutes: {
		en: "Working Minutes",
		ru: "Рабочие минуты",
		hy: "Աշխատանքային րոպեները",
	},
	processTime: {
		en: "Process Time",
		ru: "Время обработки",
		hy: "Ընթացիկ ժամանակը",
	},
	detailedSteps: {
		en: "Detailed Steps",
		ru: "Детальные шаги",
		hy: "Մանրամասն քայլերը",
	},
	inProgress: {
		en: "In Progress",
		ru: "В процессе",
		hy: "Ընթացիկ",
	},
	id: {
		en: "ID",
		ru: "ID",
		hy: "ID",
	},
	updatedAt: {
		en: "Updated At",
		ru: "Обновлено",
		hy: "Թարմացված է",
	},
	createdAt: {
		en: "Created At",
		ru: "Создано",
		hy: "Ստեղծվել է",
	},
	projectDetails: {
		en: "Project Details",
		ru: "Детали проекта",
		hy: "Նախագծի մանրամասներ",
	},
	createdByUser: {
		en: "Created By User",
		ru: "Создано пользователем",
		hy: "Ստեղծված է օգտվողի կողմից",
	},
	project: {
		en: "Project",
		ru: "Проект",
		hy: "Նախագիծ",
	},
	addFiles: {
		en: "Add Files",
		ru: "Добавить файлы",
		hy: "Ավելացնել ֆայլեր",
	},
	projectTypes: {
		en: "Project Types",
		ru: "Типы проектов",
		hy: "Նախագծի տեսակներ",
	},
	projectType: {
		en: "Project Type",
		ru: "Тип проекта",
		hy: "Նախագծի տեսակ",
	},
	low: {
		en: "Low",
		ru: "Низкий",
		hy: "Ցածր",
	},
	high: {
		en: "High",
		ru: "Высокий",
		hy: "Բարձր",
	},
	undefined: {
		en: "Undefined",
		ru: "Не определено",
		hy: "Սահմանված չէ",
	},
	cancelBack: {
		en: "Cancel back",
		ru: "Отменить обратно",
		hy: "Հետ",
	},
	removeFromList: {
		en: "Remove from list",
		ru: "Удалить из списка",
		hy: "Ջնջել ցուցակից",
	},
	users: {
		en: "Users",
		ru: "Пользователи",
		hy: "Աշխատակիցներ",
	},
	workingDays: {
		en: "Working Days",
		ru: "Рабочие дни",
		hy: "Աշխատանքային օրեր",
	},
	visitationDeadline: {
		en: "Visitation Dead Line",
		ru: "Срок рассмотрения",
		hy: "Վերջնաժամկետ տեղազննման",
	},
	steps: {
		en: "Steps",
		ru: "Шаги",
		hy: "Քայլեր",
	},
	stepsLeft: {
		en: "Steps Left",
		ru: "Осталось шагов",
		hy: "Քայլերի քանակը",
	},
	substation: {
		en: "Substation",
		ru: "Подстанция",
		hy: "Սնման կետ",
	},
	deadLine: {
		en: "Dead Line",
		ru: "Срок",
		hy: "Վերջնաժամկետ",
	},
	history: {
		en: "History",
		ru: "История",
		hy: "Պատմություն",
	},
	edited: {
		en: "Edited",
		ru: "Редактировано",
		hy: "Խմբագրված է",
	},
	added: {
		en: "Added",
		ru: "Добавлено",
		hy: "Ավելացված է",
	},
	technicalFilesUrl: {
		en: "Technical Files Url",
		ru: "URL Технические файлы",
		hy: "Տեխնիկական ֆայլերի Url",
	},
	end: {
		en: "End",
		ru: "Конец",
		hy: "Ավարտ",
	},
	openDate: {
		en: "Date opened",
		ru: "Дата открытия",
		hy: "Ստեղծման ամսաթիվը",
	},
	selectOpenedDate: {
		en: "Select opened date",
		ru: "Выберите дату открытия",
		hy: "Ընտրեք ստեղծման ամսաթիվը",
	},
	next: {
		en: "Next",
		ru: "Следующий",
		hy: "Հաջորդ",
	},
	substationNumber: {
		en: "Substation number",
		ru: "Номер подстанции",
		hy: "Սնման կետի համարը",
	},
	voltageLevel: {
		en: "Voltage level",
		ru: "Уровень напряжения",
		hy: "Լարման մակարդակ",
	},
	orderNumber: {
		en: "Order number",
		ru: "Номер заказа",
		hy: "Պատվերի համար",
	},
	propertyNumber: {
		en: "Property number",
		ru: "Номер свойства",
		hy: "Գույքահամար",
	},
	branch: {
		en: "Branch",
		ru: "Филиал электронной сети",
		hy: "էլեկտրական ցանցի մասնաճյուղ",
	},
	writingNumber: {
		en: "Writing number",
		ru: "Написание номера",
		hy: "Գրության համար",
	},
	writingContent: {
		en: "Writing content",
		ru: "Написание контента",
		hy: "Գրության բովանդակություն",
	},
	noNeedStep45: {
		en: "No Need Step 4-5",
		ru: "Не нужно шаг 4-5",
		hy: "Չի պահանջվում 4-5 քայլերը",
	},
	priority: {
		en: "Proirity",
		ru: "Приоритет",
		hy: "Կարևորություն",
	},
	actSendingDate: {
		en: '"Act" sending date',
		ru: "Дата отправки «Акт»",
		hy: "Ակտի հանձնման ամսաթիվ",
	},
	projectEndDate: {
		en: "Project End Date",
		ru: "Дата окончания проекта",
		hy: "Նախագծի ավարտի ամսաթիվ",
	},
	projectStartDate: {
		en: "Project Start Date",
		ru: "Дата начала проекта",
		hy: "Նախագծի սկիզբի ամսաթիվ",
	},
	saved: {
		en: "Saved",
		ru: "Сохранено",
		hy: "Պահպանվել է",
	},
	projectDeadline: {
		en: "Project Deadline",
		ru: "Срок проекта",
		hy: "Նախագծի վերջնաժամկետ",
	},
	yes: {
		en: "Yes",
		ru: "Да",
		hy: "Այո",
	},
	removed: {
		en: "Removed",
		ru: "Удалено",
		hy: "Ջնջելվել է",
	},
	areYouSure: {
		en: "Are you sure?",
		ru: "Вы уверенны ?",
		hy: "Դուք համոզված եք ?",
	},
	youCannotRevertThisAction: {
		en: "You cannot revert this action!",
		ru: "Вы не можете вернуть это действие!",
		hy: "Դուք չեք կարող հետ բերել այս գործողությունը հետագայում!",
	},
	pcs: {
		en: "PCs",
		ru: "ПК",
		hy: "Համակարգիչներ",
	},
	moreDetailed: {
		en: "More detailed",
		ru: "Подробнее",
		hy: "Մանրամասն",
	},
	relatedList: {
		en: "Related List",
		ru: "Связанный список",
		hy: "Համապատասխան ցուցակը",
	},
	duration: {
		en: "Duration",
		ru: "Продолжительность",
		hy: "Տևողություն",
	},
	problemDescription: {
		en: "Problem Description",
		ru: "Описание проблемы",
		hy: "Խնդիրի նկարագիր",
	},
	parent: {
		en: "Parent",
		ru: "Родитель",
		hy: "Մայր",
	},
	routers: {
		en: "Routers",
		ru: "Маршрутизаторы",
		hy: "Հեռահաղորդակցություն",
	},
	monitors: {
		en: "Monitors",
		ru: "Мониторы",
		hy: "Մոնիտորներ",
	},
	keyboards: {
		en: "Keyboards",
		ru: "Клавиатуры",
		hy: "Ստեղնաշարներ",
	},
	mouses: {
		en: "Mouses",
		ru: "Мышки",
		hy: "Մկնրկներ",
	},
	switches: {
		en: "Printers",
		ru: "Принтеры",
		hy: "Տպիչներ",
	},
	smb: {
		en: "Server folder",
		ru: "Папки сервера",
		hy: "Սերվերի պանակ",
	},
	printers: {
		en: "Switches",
		ru: "Коммуаторы",
		hy: "Անջատիչ",
	},
	openedDate: {
		en: "Open Date",
		ru: "Дата открытия",
		hy: "Բացման ամսաթիվը",
	},
	problem: {
		en: "Problem",
		ru: "Проблема",
		hy: "Խնդիր",
	},
	toChat: {
		en: "To chat",
		ru: "В чат",
		hy: "Դեպի չատ",
	},
	room: {
		en: "Room",
		ru: "Комната",
		hy: "Սենյակ",
	},
	commentTicket: {
		en: "Comment Ticket",
		ru: "Комментарий к билету",
		hy: "Մեկնաբանություն տոմսի մասին",
	},
	comment: {
		en: "Comment",
		ru: "Комментарий",
		hy: "Մեկնաբանություն",
	},
	date: {
		en: "Date",
		ru: "Дата",
		hy: "Ամսաթիվ",
	},
	humanResources: {
		en: "Human Resources",
		ru: "Кадры",
		hy: "Անձնակազմ",
	},
	database: {
		en: "Database",
		ru: "База данных",
		hy: "Տվյալների բազա",
	},
	IT: {
		en: "IT",
		ru: "ИТ",
		hy: "ՏՏ",
	},
};
const menu = [
	{
		title: {
			en: "Orders",
			ru: "Заказы",
			hy: "Պատվերներ",
		},
		icon: "project-svgrepo-com.svg",
		route: "order",
		permissionModel: "order",
	},
	{
		title: {
			en: "Products",
			ru: "Продукты",
			hy: "Ապրանքներ",
		},
		subMenu: [
			{
				name: {
					en: "Product Analytics",
					ru: "Продукты аналитика",
					hy: "Ապրանքներ անալիտիկա",
				},
				icon: "line-graph-svgrepo-com.svg",
				route: "product-analytics",
				permissionModel: "product-analytics",
			},
			{
				name: {
					en: "Product",
					ru: "Продукты",
					hy: "Ապրանքներ",
				},
				icon: "status-svgrepo-com.svg",
				route: "product",
				permissionModel: "product",
			},
			{
				name: {
					en: "Product entry",
					ru: "Продукты ввод",
					hy: "Ապրանքների մուտք",
				},
				icon: "forward-svgrepo-com.svg",
				route: "product-entry",
				permissionModel: "product-entry",
			},
		],
	},
	{
		title: {
			en: "Clients",
			ru: "Клиенты",
			hy: "Հաճախորդներ",
		},
		subMenu: [
			{
				name: {
					en: "Clients Analytics",
					ru: "Клиент аналитика",
					hy: "Հաճախորդներ անալիտիկա",
				},
				icon: "table-of-contents-svgrepo-com.svg",
				route: "client-analytics",
				permissionModel: "client-analytics",
			},
			{
				name: {
					en: "Clients",
					ru: "Клиенты",
					hy: "Հաճախորդներ",
				},
				icon: "users-svgrepo-com.svg",
				route: "client",
				permissionModel: "client",
			},
			{
				name: {
					en: "Debts",
					ru: "Долги",
					hy: "Պարտքեր",
				},
				icon: "users-svgrepo-com.svg",
				route: "debt",
				permissionModel: "debt",
			},
			{
				name: {
					en: "Debt repayment",
					ru: "Погашение долга",
					hy: "Պարտքի վերադարձ",
				},
				icon: "user-search-alt-svgrepo-com.svg",
				route: "debt-repayment",
				permissionModel: "debt-repayment",
			},
		],
	},
	{
		title: {
			en: "Encashment",
			ru: "Инкассация",
			hy: "Ինկասացիա",
		},

		icon: "dollar1-svgrepo-com.svg",
		route: "encashment",
		permissionModel: "encashment",
	},
	{
		title: {
			en: "Users",
			ru: "Пользователи",
			hy: "Աշխատակիցները",
		},
		icon: "users-svgrepo-com.svg",
		route: "user",
		permissionModel: "user",
	},
	{
		title: {
			en: "Settings",
			ru: "Настройки",
			hy: "Կարգավորումներ",
		},
		route: "settings",
		subMenu: [
			{
				name: {
					en: "Roles",
					ru: "Роли",
					hy: "Դերեր",
				},
				icon: "software-svgrepo-com.svg",
				route: "role",
				permissionModel: "role",
			},
			{
				name: {
					en: "Permissions",
					ru: "Разрешения",
					hy: "Թույլատրություններ",
				},
				icon: "box-full-svgrepo-com.svg",
				route: "permission",
				permissionModel: "permission",
			},
		],
	},
];
const notificationSettings = {
	position: "top-right",
	autoClose: 2500,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	save: function (state, lang) {
		if (state === "success") {
			return {
				title: translate.saved[lang],
				message: translate.yourFileHasBeenSaved[lang],
				autoClose: this.autoClose,
				color: "green",
			};
		}
		if (state === "error") {
			return {
				title: translate.error[lang],
				message: translate.yourFileHasBeenNotSaved[lang],
				color: "red",
			};
		}
	},
	delete: function (state, lang) {
		if (state === "success") {
			return {
				title: translate.deleted[lang],
				message: translate.yourFileHasBeenDeleted[lang],
				autoClose: this.autoClose,
				color: "green",
			};
		}
		if (state === "error") {
			return {
				title: translate.error[lang],
				message: translate.yourFileHasBeenNotSaved[lang],
				color: "red",
			};
		}
	},
};
const config = {
	api,
	authentification,
	brand,
	translate,
	menu,
	notificationSettings,
};

export default config;
