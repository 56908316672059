import React, { useState, useEffect, useMemo } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress from "nprogress";

export default function Debt() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.debt;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const [ID, setID] = useState("");

	const [amount, setAmount] = useState(0);
	const [date, setDate] = useState(new Date());
	const [client, setClient] = useState("");
	const [allClients, setAllClients] = useState([]);
	const [order, setOrder] = useState("");
	const [allOrders, setAllOrders] = useState([]);

	const modelSendToServer = {
		ID,
		amount,
		client,
		order,
		date,
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all clients
			let clients = [];

			try {
				clients = await API.get(config.api.client, {});
				setAllClients(clients);
			} catch (error) {
				console.log(error);
			}

			let orders = [];
			try {
				orders = await API.get(config.api.order, {});
				setAllOrders(orders);
			} catch (error) {
				console.log(error);
			}

			let rawID = [];
			try {
				rawID = await API.get(route, { temporary: { $ne: true } }, { ID: "desc" }, {}, 1);
			} catch (error) {
				console.log(error);
			}

			if (id !== "add") {
				let currentItem = await API.get(route, {
					_id: id,
				});

				setID(
					currentItem[0]?.ID ? currentItem[0]?.ID : rawID[0]?.ID ? rawID[0]?.ID + 1 : 1
				);
				setClient(currentItem[0]?.client);
				currentItem[0]?.date && setDate(new Date(currentItem[0]?.date));
				setOrder(currentItem[0]?.order);
				setAmount(currentItem[0]?.amount);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "client") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel="debt"
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: ID,
						setter: setID,
						type: "number",
						usageType: "number",
						label: "ID",
						permissionModel: "ID",
					},
					{
						value: client,
						setter: setClient,
						type: "optionlist",
						usageType: "optionlist",
						optionListValue: allClients.map(
							(client) => ({
								value: client._id,
								label: client.name,
							}),
							[]
						),
						label: config.translate.client[lang],
						placeholder: config.translate.client[lang],
						permissionModel: "client",
					},
					{
						value: "order/"+order,
						// setter: setClient,
						type: "link",
						usageType: "link",
						optionListValue: allOrders.map(
							(order) => ({
								value: order._id,
								label: String(order.ID),
							}),
							[]
						),
						disabled: true,
						label: config.translate.order[lang],
						placeholder: config.translate.order[lang],
						permissionModel: "order",
					},
					{
						value: amount,
						setter: setAmount,
						type: "number",
						usageType: "number",
						label: config.translate.price[lang],
						placeholder: config.translate.price[lang],
						permissionModel: "amount",
					},
					{
						value: date,
						setter: setDate,
						type: "datePicker",
						usageType: "datePicker",
						label: config.translate.date[lang],
						placeholder: config.translate.date[lang],
						permissionModel: "date",
					},
				]}
			/>
		);
}
